<template>
  <span class="categories">
     <span v-for="(category, idx) in categories" :key="category">
        <a href="#" @click.prevent="emitCategory(category)">{{category}}</a><span v-if="(idx+1)<categories.length">, </span>
     </span>
  </span>
</template>

<script>
export default {
  name: 'CategoryList',
  props: { categories: Array },
  methods: {
    emitCategory(category) {
      this.$emit('change', category);
    }
  }
}
</script>
