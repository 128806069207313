<template>
  <div class="recipe-content-area container" v-if="currentRecipe && currentRecipe._id == id">
    <div class="row top">
      <div class="col-12 col-xl-10">
        <div class="recipe-headline">
          <h2 class="recipe-content">{{currentRecipe.name}}  <b-button :to="'/recipe/' + currentRecipe._id + '/edit'" variant="primary" class="float-right no-print">Edit</b-button></h2>
          <h4><RecipeFlags v-model="currentRecipe.flags" v-if="currentRecipe.flags" :readonly="true" /></h4>
        </div>
      </div>
      <div class="col-12 col-xl-2">
        <div class="recipe-ratings text-right">
          <div class="ratings">
            <star-rating v-model="currentRecipe.rating" :read-only="true" :show-rating="false" :star-size="25" :clearable="true" :inline="true" :increment="0.5"  />
          </div>
        </div>
      </div>
    </div>
    <div class="recipe-info row">
      <div class="col-12 col-md-6" v-if="currentRecipe.source"><h6 class="recipe-source"><fa :icon="['fab', 'sourcetree']" style="width: 20px"/> Source: {{currentRecipe.source}}</h6></div>
      <div class="col-12 col-md-6" v-if="currentRecipe.categories && currentRecipe.categories.length > 0"><h6 class="recipe-categories"><fa :icon="['fas', 'tags']" style="width: 20px" /> Categories: <CategoryList :categories="currentRecipe.categories" @change="changeCategory" /></h6></div>
      <div class="col-12 col-md-6" v-if="currentRecipe.prepTime"><h6 class="recipe-preptime"><fa :icon="['fas', 'clock']" style="width: 20px" /> Prep Time: {{humanize(currentRecipe.prepTime)}}</h6></div>
      <div class="col-12 col-md-6" v-if="currentRecipe.bakeTime"><h6 class="recipe-baketime"><fa :icon="['fas', 'clock']" style="width: 20px" /> Bake Time: {{humanize(currentRecipe.bakeTime)}}</h6></div>
      <div class="col-12 col-md-6" v-if="currentRecipe.bakeTemperature"><h6 class="recpie-baketemp"><fa :icon="['fas', 'temperature-high']" style="width: 20px" /> Bake Temp: {{currentRecipe.bakeTemperature}}</h6></div>
      <div class="col-12 col-md-6" v-if="currentRecipe.servings"><h6 class="recipe-servings"><fa :icon="['fas', 'utensils']" style="width: 20px" /> Servings: {{humanize(currentRecipe.servings)}}</h6></div>
      <div class="col-12 col-xl-12" v-if="currentRecipe.conversion && currentRecipe.conversion.complete==false"><b-badge variant="danger">incomplete</b-badge></div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="recipe-description" v-if="currentRecipe.description">
          <p>{{currentRecipe.description}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-4">
        <div class="ingredients" v-if="currentRecipe.ingredients != null && system != null">
          <h4>Ingredients</h4>
          <div class="recipe-system no-print" v-if="system != null"><span v-for="(name,idx) in systemsOfMeasure" :key="idx"><a href="#" @click.prevent="setSystem(name)" v-if="system != name">{{toTitleCase(name)}}</a><b v-else>{{toTitleCase(name)}}</b><span v-if="((idx+1) < systemsOfMeasure.length)">&nbsp;|&nbsp;</span></span></div>
          <div v-for="(segment,sidx) in Object.keys(ingredientsBySegment)" :key="sidx">
            <h5 v-if="segment != 'undefined'">{{segment}}</h5>
            <div v-for="(item,idx) in ingredientsBySegment[segment]" :key="idx" class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" />
              <label :class="ingredientClass(item.optional, item.deleted)"><span v-if="item.quantity">{{humanize(item.quantity)}}&nbsp;</span><span v-if="item.measure">{{item.measure}}&nbsp;</span>{{item.ingredient}}<span v-if="item.comments">&nbsp;<span :id="'comment-' + segment + '-' + idx.toString()"><fa :icon="['fas', 'comment-alt']" /><b-tooltip :target="'comment-' + segment + '-' + idx.toString()">{{item.comments}}</b-tooltip></span></span><span v-if="item.alternatives && item.alternatives.length > 0">&nbsp;<fa :icon="['fas', 'plus']" :id="'alternatives-' + segment + '-' + idx.toString()" /><b-tooltip :target="'alternatives-' + segment + '-' + idx.toString()">Alternatives<br/><div v-for="(aitem, aidx) in item.alternatives" :key="aidx"><span v-if="aitem.quantity">{{aitem.quantity}}&nbsp;</span><span v-if="aitem.measure">{{aitem.measure}}&nbsp;</span>{{aitem.ingredient}}<span v-if="aitem.comments">&nbsp;<span :id="'comment-' + segment + '-' + idx.toString() + '-' + aidx.toString()"><fa :icon="['fas', 'comment-alt']" /><b-tooltip :target="'comment-' + segment + '-' + idx.toString() + '-' + aidx.toString()">{{aitem.comments}}</b-tooltip></span></span><span v-if="(aidx+1) < item.alternatives.length"><br/>OR</span></div></b-tooltip></span></label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-8 recipe-instructions">
        <h4>Directions</h4>
        <div class="single-preparation-step d-flex" v-for="(item,idx) in currentRecipe.instructions" :key="idx">
          <h4>{{ zeroPad((idx+1),2) }}.</h4>
          <p>{{humanize(item)}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-4">
        <div v-if="currentRecipe.nutritionalInformation != null">
          <NutritionFacts :info="currentRecipe.nutritionalInformation" />
        </div>
      </div>
      <div class="col-12 col-xl-8">
        <div class="recipe-notes" v-if="currentRecipe.notes">
          <h3>Notes</h3>
          <p>{{currentRecipe.notes}}</p>
        </div>
      </div>
    </div>
    <div v-for="(variant,idx) in currentRecipe.variations" :key=idx>
      <RecipeVariant :variant="variant" :parentSystem="system" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapDebugActions } from '@/lib/debug';

//import json from '@/json/recipes.json';
import StarRating from 'vue-star-rating';
import NutritionFacts from '@/components/NutritionFacts.vue';
import CategoryList from '@/components/CategoryList.vue';
import RecipeFlags from '@/components/RecipeFlags.vue';
import RecipeVariant from '@/components/RecipeVariant.vue';
import _ from 'lodash';

export default {
  name: 'Recipe',
  props: { id: String },
  data() {
    return {
      system: null
    }
  },
  components: {
    StarRating,
    NutritionFacts,
    CategoryList,
    RecipeFlags,
    RecipeVariant
  },
  mounted () {
    this.debugMessage ('<<MOUNTED>>');
    this.debugObject ({params: this.$route.params})
    this.fetchRecipe(this.id).then((v) => {
       if (v) {
          this.system = this.currentRecipe.ingredients[0].system;
       }
    });
  },
  methods: {
    ...mapActions({ "fetchRecipe": "recipe/fetchRecipe" }),
    ...mapDebugActions,
    toTitleCase: (s) => {
      return s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();
    },
    ingredientClass(opt, del) {
      return 'custom-control-label' + (((opt != null) && opt)?' optional':'') + (((del != null) && del)?' deleted':'');
    },
    zeroPad( num, numLen ) {
      return num.toString().padStart(numLen, '0');
    },
    setSystem( newSystem) {
      this.system = newSystem;
    },
    changeCategory( category ) {
      this.$emit('input', '?categories=' + category);
      //this.$router.push({ name: 'Home', params: { category: category } });
      this.$router.push({ name: 'Home' });
    },
    humanize (text) {
      return text.replace('1/4', '¼').replace('1/2', '½').replace('3/4', '¾').replace('1/3', '⅓').replace('2/3', '⅔').replace('1/5', '⅕').replace('2/5', '⅖').replace('3/5', '⅗').replace('4/5', '⅘').replace('1/6', '⅙').replace('5/6', '⅚').replace('1/8', '⅛').replace('3/8', '⅜').replace('5/8', '⅝').replace('7/8', '⅞');
    }
  },
  computed: {
    ...mapGetters({ "currentRecipe": "recipe/currentRecipe" }),
    systemsOfMeasure() {
      return this.currentRecipe.ingredients.map( x => x.system ).filter( (x, idx, self) => { return self.indexOf(x) === idx } );
    },
    ingredientsBySegment() {
      return _.groupBy(this.currentRecipe.ingredients.filter ( x => x.system == this.system ), ingredient => ingredient.segment);
    }
  }
}
</script>

<style scoped>
.optional { color: #9b9b9b; }
.deleted  { color: #555; text-decoration: line-through; }

.mb-5, .my-5 {
    margin-bottom: 3rem!important;
}

.mt-5, .my-5 {
    margin-top: 3rem!important;
}

.recipe-info {
    border-left: 3px solid #40ba37;
    padding: 15px;
}

h1, h2, h3, h4, h5, h6 {
    color: #000000;
    line-height: 1.3;
    font-weight: 600;
}

.h2, h2 {
    font-size: 2rem;
}

body {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.recipe-ratings .ratings {
    margin-bottom: 30px;
}

p {
    font-size: 14px;
    line-height: 2;
    font-weight: 400;
}

.single-preparation-step {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}

.single-preparation-step h4 {
    color: #474747;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    max-width: 60px;
    width: 60px;
    margin-bottom: 0;
}

.ingredients h4 {
    color: #474747;
    /* margin-bottom: 30px; */
}

.ingredients .custom-control {
    padding-left: 2.5rem;
    min-height: 35px;
}

.custom-control-label::before {
    left: -2.5rem;
}


.recipe-system {
    margin-bottom: 20px;
}

.row.top {
    margin-top: 20px;
}
</style>
