<template>
  <div class="nutrition-chart">
     <h4>Nutrition Facts</h4>
     <h5 class="nutrition-serving-size">Serving Size: <span v-if="info.servingSize">{{info.servingSize}}</span><span v-else>1 Serving</span></h5>
     <dl>
       <div v-for="k in Object.keys(info)" :key="k">
         <template v-if="(typeof info[k] != 'object') && (k != 'servingSize')">
           <dt class="info">{{k}}</dt>
           <dd class="info">{{info[k]}}</dd>
         </template>
       </div>
     </dl>
  </div>
</template>

<script>
export default {
  name: 'NutritionFacts',
  props: { info: Object }
}
</script>

<style scoped>
.nutrition-chart {
  margin-top: 50px;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  padding: 10px 10px 10px 10px;
}
.nutrition-chart h4 {
    color: #333;
    font-family: 'Museo Slab',Times,serif;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: bold;
    padding: 15px 0 10px;
}
.nutrition-chart .nutrition-serving-size {
    color: #333;
    font-family: 'Museo Slab',Times,serif;
    font-size: .625rem;
    line-height: .875rem;
    padding-bottom: 8px;
}
.nutrition-chart .nutrition-calories {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 5px 0;
    text-align: right;
}
.nutrition-chart dt {
    display: inline;
    font-weight: bold;
}
.nutrition-chart dt.info {
    float: left;
    clear: both;
    box-sizing: border-box;
    width: 60%;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
}
.nutrition-chart dd {
    display: inline;
    padding-left: 5px;
    font-weight: normal;
}
.nutrition-chart dd.info {
    display: inline-block;
    box-sizing: border-box;
    width: 40%;
    text-align: right;
    border-bottom: 1px solid #ccc;
    padding: 5px 0;
}
</style>
